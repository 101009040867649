<template>
	<div class="relative main-h-screen flex">
		<div class="w-full">
			<div class="bg-gray-100 bg-white">
				<div class="flex text-sm justify-center">
					<div class="w-fill  bg-white shadow-md p-2">
						<nav class="flex mb-10" aria-label="Breadcrumb">
							<ol class="inline-flex items-center space-x-1 md:space-x-3">
								<li class="inline-flex items-center">
									<router-link
										:to="{ name: 'Home' }"
										class="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white"
									>
										<svg class="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
											></path>
										</svg>
										Home
									</router-link>
								</li>

								<li aria-current="page">
									<div class="flex items-center">
										<svg class="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
											<path
												fill-rule="evenodd"
												d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
												clip-rule="evenodd"
											></path>
										</svg>
										<span class="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{{ title }}</span>
									</div>
								</li>
							</ol>
							<hr />
						</nav>
						<div class="space-y-10">
							<div class="space-y-2">
								<h2 class="text-2xl text-gray-700 font-semibold">{{ title }}</h2>
							</div>
							<hr />
							<div class="space-y-5">
								<p class="text-left font-semibold mb-5 text-gray-700">Please see instructions on how to subscribe below.</p>
								<p class="text-left text-gray-700 bg-yellow-300 inline font-semibold">PLEASE NOTE: YOU MAY REQUIRE A VPN which we do NOT provide</p>
							</div>
							<div class="">
								<h5 class="text-lg font-semibold">
									1.To subscribe go to our website
									<a :href="`${$store.state.brandInfo.siteLink}/#pricing`" class="hover:text-blue-700">{{ $store.state.brandInfo.siteLink }}/#pricing</a>
								</h5>
								<div class="text-center space-y-5 mt-3 mb-10 text-yellow-700">
									<p>NOTE: PAYPAL IS NO LONGER AVAILABLE</p>
									<p>ALSO INCOGNITO MODE VIA WEB BROWSER WON'T WORK</p>
								</div>
								<p>
									2.Once you're on our website, select the subscription period you want to buy <span class="font-semibold">1, 3, 6, or 12 months</span> and then
									click the "<span class="font-semibold">Subscribe now</span>" button.
								</p>
								<div class="space-y-10">
									<div class="mt-5">
										<p>EXAMPLE:</p>
										<a href="./../../../assets/images/mini-icon/pricing.png"
											><img src="./../../../assets/images/mini-icon/pricing.png" alt="Pricing Template"
										/></a>
									</div>
									<div class="space-y-5">
										<p>3. <span class="bg-yellow-300 font-semibold">You will be taken to screen below</span>.</p>
										<a href="" class="block"><img src="./../../../assets/images/mini-icon/account-area.png" alt="Account Area" /></a>
									</div>
									<div class="space-y-10">
										<div class="space-y-3">
											<p>4. <span class="font-semibold bg-yellow-300 inline">SELECT NEW ACCOUNT</span></p>
											<p style="margin-top: 20px" class="font-semibold bg-yellow-300 inline">THEN FILL OUT REQUIRED INFORMATION AS SHOWN BELOW.</p>
										</div>
										<img src="./../../../assets/images/mini-icon/create-account.png" alt="Account Create" />
										<div>
											<ul class="space-y-1">
												<li>Fill out the <span class="font-semibold">billing details form using a valid Name and Email address.</span></li>
												<li class="font-semibold">Important! Use a VALID email address that you own and that is easily accessible to you.</li>
												<li class="font-semibold">This is the email address you will receive your login username and password.</li>
												<li class="font-semibold">This email address will also be used for your login credentials for all apps.</li>
												<li class="font-semibold">YOU MUST INCLUDE YOUR PHONE NUMBER ALSO</li>
												<li>
													This email address can <span class="font-semibold">NOT</span> be changed afterwards so make sure to use a valid email address
													you wish to use for the entire duration of your subscription.
												</li>
												<li class="font-semibold">*Double-check that the email address is spelt correctly before clicking SUBMIT button.</li>
											</ul>
										</div>
									</div>
									<div class="space-y-10">
										<p>5. <span class="font-semibold bg-yellow-300">AFTER CLICKING THE SUBMIT YOU WILL BE TAKEN TO THE SCREEN BELOW.</span></p>
										<img src="../../../assets/images/mini-icon/create-account2.png" alt="your-registration-successfully-img" />
										<div class="space-y-10">
											<p>6. <span class="font-semibold bg-yellow-300">''VERY IMPORTANT''</span></p>
											<div>
												<p class="font-semibold inline bg-yellow-300">CHECK YOUR EMAIL FOR LOGIN PASSWORD</p>
											</div>
											<div class="space-y-5">
												<p class="font-semibold inline bg-yellow-300">ONCE YOU HAVE PASSWORD CLICK LOGIN AND TYPE YOUR EMAIL AND PROVIDED PASSWORD ON</p>
												<p class="font-semibold inline bg-yellow-300">SIGN-IN SCREEN AS SHOWN BELOW.</p>
											</div>
											<img src="../../../assets/images/mini-icon/sign-in.png" class="mt-10" alt="Sign In" />
										</div>
										<div>
											<p>
												7. <span class="bg-yellow-300 inline font-semibold">ONCE YOU HAVE SUCCESSFULLY LOGGED IN YOU WILL BE TAKEN TO SCREEN BELOW.</span>
											</p>
										</div>
										<img src="./../../../assets/images/mini-icon/profile.png" alt="subscribe-img" />
										<p class="font-semibold">8.<span class="bg-yellow-300">SELECT SUBCRIBE NOW AND YOU WILL BE SENT TO SCREEN BELOW</span></p>
										<img src="./../../../assets/images/mini-icon/subscriptions.png" alt="subscribe-img02" />
										<div class="font-semibold text-xs">
											<p class="">9. <span class="inline bg-yellow-300">FROM HERE</span></p>
											<br />
											<p class=""><span class="inline bg-yellow-300">CLICK THE DROP DOWN MENU UNDER CHOOSE PACKAGE AND SELECT THE PACKAGE YOU WANT.</span></p>
										</div>
										<!-- <img src="./../../../assets/images/mini-icon/dailyexpiry.png" alt="" /> -->
										<p class="font-semibold">10. <span class="bg-yellow-300">SELECT NEXT AFTER CHOOSING PACKAGE OF CHOICE</span></p>
										<p class="font-semibold"><span class="bg-yellow-300">NEXT PAGE WILL TAKE YOU TO OUR PAYMENT SCREEN BELOW</span></p>
										<img v-if="!isPizza" src="./../../../assets/images/mini-icon/select-payment-method.jpeg" alt="select-payment-method" />
										<div class="font-semibold space-y-6 text-gray-800">
											<p>11. <span class="bg-yellow-300">CHOOSE YOUR PAYMENT METHOD.</span></p>
											<p>
												<span class="bg-yellow-300"
													>{{ isPizza ? '' : 'CURRENTLY CREDIT/DEBIT CARD AND ' }}BITCOIN ARE THE ONLY ACCEPTED PAYMENT METHODS.</span
												>
											</p>
											<p><span class="bg-yellow-300">ADDITIONAL PAYMENT METHODS COMING SOON .</span></p>
											<p>
												<span class="bg-yellow-300">{{ isPizza ? '' : 'FOR THIS TUTORIAL WE WILL SELECT CARD.' }}</span>
											</p>
										</div>
										<img v-if="!isPizza" src="./../../../assets/images/mini-icon/subscription.png" alt="subscription" />
										<div class="font-semibold" v-if="!isPizza">
											<p><span class="bg-yellow-300">SIMPLY FILL OUT YOUR PAYMENT INFORMATION THEN CLICK SUBMIT AND</span></p>
											<p><span class="bg-yellow-300">THATS IT!</span></p>
										</div>
										<div>
											<ul class="font-semibold text-xs space-y-2">
												<li>IMPORTANT: Please email us at {{ $store.state.brandInfo.billingEmail }} for additional assistance.</li>
												<li>We will email you back as soon as possible. Please do NOT send multiple emails.</li>
												<li>
													Email us from the account email address you signed up with <span class="font-normal">and provide</span> ALL
													<span class="font-normal">of the following information.</span>
												</li>
											</ul>
										</div>
										<div class="space-y-2">
											<p class="font-semibold">Please see how to send bitcoin from CoinBase</p>
											<a
												class="block font-semibold hover:text-blue-500"
												target="_blank"
												href="https://support.coinbase.com/customer/en/portal/articles/971437-how-do-i-send-digital-currency-to-another-wallet-"
												>https://support.coinbase.com/customer/en/portal/articles/971437-how-do-i-send-digital-currency-to-another-wallet</a
											>
										</div>
										<p>-To RENEW your account please click link below</p>
										<router-link :to="{ name: 'HowDoIRenewMySubscription' }"
											><p class="hover:text-blue-500 font-semibold">How do I renew my subscription?</p></router-link
										>
									</div>
								</div>
							</div>
							<hr />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'HowDoISubscribe',
	data() {
		return {
			title: this.$route.meta.title //
		};
	},
	computed: {
		...mapState({
			isPizza: (state) => state.brandInfo.name === 'pizzatime'
		})
	}
};
</script>

<style scoped></style>
